<template>
  <span class="badge">
    Шаг {{ currentStep + 1 }} из {{ maxSteps + 1 }}
  </span>
</template>

<script>

export default {
  name: 'StepBadge',
  props: {
    currentStep: {
      type: Number,
      required: true
    },
    maxSteps: {
      type: Number,
      required: true
    }
  },
  methods: {
    changeStep (index) {
      this.$emit('change', index)
    }
  }
}
</script>

