<template>
  <div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ getField('children').title }}</span>
        <div class="form-group__tooltip" v-if="getField('children').description">
          <i
            class="icon"
            aria-describedby="tooltip"
            v-tooltip="{ content: getField('children').description, trigger: $mq === '700' ? 'click' : 'hover' }"
            tabindex="1"
          >
            <TooltipIcon/>
          </i>
        </div>
      </div>
      <div class="form-group__body">
        <div class="row" id="underage_children" v-if="countChild">
          <div class="form-group__multiple" ref="children"  v-for="(children, index) in stepForm.children.value" :key="index">
            <div class="_row">
              <div class="_col-4">
                <ValidationProvider
                  name=" "
                  rules="required"
                  v-slot="{ errors }"
                  style="width: 100%"
                >
                  <InputField
                    :required="true"
                    :title="getField('children_surname').title"
                    :placeholder="getField('children_surname').title"
                    v-model="children.surname.value"
                  >
                    <span class="form-group__message__error" v-if="!children.surname.message">{{ errors[0] }}</span>
                    <span
                      class="form-group__message__error"
                      v-if="!children.surname.value && children.surname.message"
                    >
                      {{ children.surname.message }}
                    </span>
                  </InputField>
                </ValidationProvider>
              </div>
              <div class="_col-4">
                <ValidationProvider
                  name=" "
                  rules="required"
                  v-slot="{ errors }"
                  style="width: 100%"
                >
                  <InputField
                    :required="true"
                    :title="getField('children_name').title"
                    :placeholder="getField('children_name').title"
                    v-model="children.name.value"
                  >
                    <span class="form-group__message__error" v-if="!children.name.message">{{ errors[0] }}</span>
                    <span class="form-group__message__error" v-if="!children.name.value && children.name.message">
                      {{ children.name.message }}
                    </span>
                  </InputField>
                </ValidationProvider>
              </div>
              <div class="_col-4">
                <InputField
                  :required="true"
                  :title="getField('children_patronymic').title"
                  :placeholder="getField('children_patronymic').title"
                  v-model="children.patronymic.value"
                >
                  <span
                    class="form-group__message__error"
                    v-if="!children.patronymic.value && children.patronymic.message"
                  >
                    {{ children.patronymic.message }}
                  </span>
                </InputField>
              </div>
              <div class="_col-4">

                <DatePickerComponent
                  v-if="mount"
                  v-model="children.birthday.value"
                  @blur="birthday(children.birthday.value, index)"
                />

                <span class="form-group__message__error" v-if="children.validator && !children.birthday.message">
                   Поле обязательно для заполнения
                </span>
                <span class="form-group__message__error" v-if="!children.birthday.value && children.birthday.message">
                  {{ children.birthday.message }}
                </span>
              </div>
            </div>
            <div class="form-group__multiple__remove">
              <button class="button button--hollow" type="button" @click="removeChildren(index)">
                <PlusIcon/>
              </button>
            </div>
          </div>
        </div>
        <div class="form-group__multiple__add">
          <button type="button" class="button button--hollow" @click="addChildren()">
            <span>Добавить ребёнка</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from 'components/svg/Tooltip.vue'
import PlusIcon from 'components/svg/Plus.vue'
import InputField from 'components/InputField.vue'
import { updateErrorsMixin } from '@/mixins/updateErrorsMixin.js'
import { resetValidationMixin } from '@/mixins/resetValidationMixin.js'
import { scrollToErrorsMixin } from '@/mixins/scrollToErrorsMixin.js'

export default {
  name: 'AlimentStepThree',
  mixins: [updateErrorsMixin, resetValidationMixin, scrollToErrorsMixin],
  components: {
    DatePickerComponent: () => import('@/components/DatePickerComponent.vue'),
    PlusIcon,
    TooltipIcon,
    InputField
  },
  data () {
    return {
      mount: false,
      errorsCount: 0,
      countChild: 0,
      stepForm: {
        children: {
          value: [],
          message: null
        }
      },
      underageChildrenExampleFields: {
        surname: {
          value: '',
          message: null
        },
        name: {
          value: '',
          message: null
        },
        patronymic: {
          value: '',
          message: null
        },
        birthday: {
          value: null,
          message: null
        },
        validator:  false
      },

    }
  },
  mounted () {
    this.mount = true
    if (localStorage.countChild) {
      Object.assign(this.countChild, JSON.parse(localStorage.countChild))
    }
    if (localStorage.AlimentStepThree) {
      Object.assign(this.stepForm, JSON.parse(localStorage.AlimentStepThree))
    }
  },
  watch: {
    stepForm: {
      handler: function () {
        localStorage.AlimentStepThree = JSON.stringify((this.stepForm))
        this.$store.dispatch('ADD_ALIMENT_STEPFORM', this.stepForm)
      },
      deep: true
    },
    countChild: function (value) {
      localStorage.countChild = this.countChild
      this.$emit('countChild', value)
    }
  },
  methods: {
    birthday (value, index) {
      this.stepForm.children.value[index].validator = !value
    },
    getField (name) {
      return this.$store.state.aliment_bot_page.values_bot.find(item => {
        return item.name === name
      })
    },
    /**
     * Валидация данных этого шага формы
     */
    validate () {
      // TODO сделать валидацию
      return true
    },
    /**
     * Обнуление полей при скрытии из за radio
     * @param fields
     */
    setNull (fields) {
      fields.forEach(field => {
        if (typeof this.stepForm[field].value === 'object' && this.stepForm[field].value !== null) {
          this.stepForm[field].value = []
        } else {
          this.stepForm[field].value = null
        }
      })
      if (this.stepForm.children.value.length === 0) {
        this.addChildren()
      }
    },
    /**
     * Добавление полей в массив в который указываются дети
     */
    addChildren () {
      this.countChild++
      this.stepForm.children.value.push(JSON.parse(JSON.stringify(this.underageChildrenExampleFields)))
    },
    /**
     * Удаление полей и массива в который указываются дети
     * @param index - Number
     */
    removeChildren (index) {
      this.countChild--
      this.stepForm.children.value.splice(index, 1)
    },
    getErrorsCount () {
      return this.errorsCount
    }
  }
}
</script>
