<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ getField('spouse_fio').title }}</span>
        <span class="form-group__tooltip" v-if="getField('spouse_fio').description">
          <i
            class="icon"
            aria-describedby="tooltip"
            v-tooltip="{ content: getField('spouse_fio').description, trigger: $mq === '700' ? 'click' : 'hover' }"
            tabindex="1"
          >
            <TooltipIcon/>
          </i>
        </span>
      </div>
      <div class="form-group__body">
        <div class="_row">
          <div class="_col-4" ref="spouse_surname">
            <ValidationProvider
              name=" "
              rules="required"
              v-slot="{ errors }"
              style="width: 100%"
            >
              <InputField
                :required="true"
                :title="getField('spouse_surname').title"
                :placeholder="getField('spouse_surname').title"
                v-model="stepForm.spouse_surname.value"
                :autofocus="true"
              >
                <span class="form-group__message__error" v-if="!stepForm.spouse_surname.message">{{ errors[0] }}</span>
                <span
                  class="form-group__message__error"
                  v-if="!stepForm.spouse_surname.value && stepForm.spouse_surname.message"
                >
                  {{ stepForm.spouse_surname.message }}
                </span>
              </InputField>
            </ValidationProvider>
          </div>
          <div class="_col-4" ref="spouse_name">
            <ValidationProvider
              name=" "
              rules="required"
              v-slot="{ errors }"
              style="width: 100%"
            >
              <InputField
                :required="true"
                :title="getField('spouse_name').title"
                :placeholder="getField('spouse_name').title"
                v-model="stepForm.spouse_name.value"
              >
                <span class="form-group__message__error" v-if="!stepForm.spouse_name.message">{{ errors[0] }}</span>
                <span
                  class="form-group__message__error"
                  v-if="!stepForm.spouse_name.value && stepForm.spouse_name.message"
                >
                  {{ stepForm.spouse_name.message }}
                </span>
              </InputField>
            </ValidationProvider>
          </div>
          <div class="_col-4" ref="spouse_patronymic">
            <InputField
              :title="getField('spouse_patronymic').title"
              :placeholder="getField('spouse_patronymic').title"
              v-model="stepForm.spouse_patronymic.value"
            >
            <span class="form-group__message__error" v-if="stepForm.spouse_patronymic.message">
              {{ stepForm.spouse_patronymic.message }}
            </span>
            </InputField>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ getField('spouse_address').title }}</span>
        <div class="form-group__tooltip" v-if="getField('spouse_address').description">
          <i
            class="icon"
            aria-describedby="tooltip"
            v-tooltip="{ content: getField('spouse_address').description, trigger: $mq === '700' ? 'click' : 'hover' }"
            tabindex="1"
          >
            <TooltipIcon/>
          </i>
        </div>
      </div>
      <div class="form-group__body">
        <div class="_row spouse-address">
          <div class="_col-8" ref="spouse_addresss">
            <VSelect
              label="value"
              :disabled="spouseAddressSimilar"
              ref="spouse_address"
              v-model="stepForm.spouse_address.value"
              :options="addresses"
              :placeholder="getField('spouse_address').title"
              :searchable="true"
              @search="fetchAddress"
              @search:focus="spouseAddressFocus"
              @search:blur="spouseAddressFocus"
              :filterable="false"
            >
              <template v-slot:no-options="{ _search, searching, loading }">
                <template v-if="searching">
                  <template v-if="!loading && !addresses.length">Ничего не найдено</template>
                  <template v-else>Загрузка...</template>
                </template>
                <template v-else>Начните вводить адрес</template>
              </template>
            </VSelect>
            <span
              class="form-group__message__error"
              v-if="!stepForm.spouse_address.value && validator.spouseAddress && !stepForm.spouse_address.message"
            >
              Поле обязательно для заполнения
            </span>
            <span
              class="form-group__message__error"
              v-if="!stepForm.spouse_address.value && stepForm.spouse_address.message"
            >
              {{ stepForm.spouse_address.message }}
            </span>
          </div>
          <div class="_col-4">
            <label class="form-group__radio divorce_form-group__radio--similar">
              <input type="checkbox" v-model="spouseAddressSimilar">
              <CheckboxStatesIcon/>
              <span class="form-group__radio__title">{{ getField('match_my_address').title }}</span>
              <span class="form-group__tooltip" v-if="getField('match_my_address').description">
                <i
                  class="icon"
                  aria-describedby="tooltip"
                  v-tooltip="{ content: getField('match_my_address').description, trigger: $mq === '700' ? 'click' : 'hover' }"
                  tabindex="1"
                >
                  <TooltipIcon/>
                </i>
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ getField('spouse_birthday_and_birthplace').title }}</span>
        <div class="form-group__tooltip" v-if="getField('spouse_birthday_and_birthplace').description">
          <i
            class="icon"
            aria-describedby="tooltip"
            v-tooltip="{ content: getField('spouse_birthday_and_birthplace').description, trigger: $mq === '700' ? 'click' : 'hover' }"
            tabindex="1"
          >
            <TooltipIcon/>
          </i>
        </div>
      </div>
      <div class="form-group__body">
        <div class="_row">
          <div class="_col-3" ref="spouse_birthday">
            <DatePickerComponent
              v-if="mount"
              v-model="stepForm.spouse_birthday.value"
            />
            <span class="form-group__message__error" v-if="stepForm.spouse_birthday.message">
              {{ stepForm.spouse_birthday.message }}
            </span>
          </div>
          <div class="_col-9">
            <InputField
              :required="true"
              :title="getField('spouse_birthplace').title"
              :placeholder="getField('spouse_birthplace').title"
              v-model="stepForm.spouse_birthplace.value"
            >
              <span class="form-group__message__error" v-if="stepForm.spouse_birthplace.message">
                {{ stepForm.spouse_birthplace.message }}
              </span>
            </InputField>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ getField('spouse_workplace').title }}</span>
        <div class="form-group__tooltip" v-if="getField('spouse_workplace').description">
          <i
            class="icon"
            aria-describedby="tooltip"
            v-tooltip="{ content: getField('spouse_workplace').description, trigger: $mq === '700' ? 'click' : 'hover' }"
            tabindex="1"
          >
            <TooltipIcon/>
          </i>
        </div>
      </div>
      <div class="form-group__body">
        <div class="_row">
          <div class="_col-12" ref="spouse_workplace">
            <InputField
              :required="true"
              :title="getField('spouse_workplace').title"
              :placeholder="getField('spouse_workplace').title"
              v-model="stepForm.spouse_workplace.value"
            >
              <span class="form-group__message__error" v-if="stepForm.spouse_workplace.message">
                {{ stepForm.spouse_workplace.message }}
              </span>
            </InputField>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxStatesIcon from 'components/svg/CheckboxStates.vue'
import TooltipIcon from 'components/svg/Tooltip.vue'
import InputField from 'components/InputField.vue'
import { updateErrorsMixin } from '@/mixins/updateErrorsMixin.js'
import { resetValidationMixin } from '@/mixins/resetValidationMixin.js'
import { fetchAddressMixin } from '@/mixins/fetchAddressMixin.js'
import { scrollToErrorsMixin } from '@/mixins/scrollToErrorsMixin.js'

export default {
  name: 'AlimentStepTwo',
  mixins: [updateErrorsMixin, resetValidationMixin, fetchAddressMixin, scrollToErrorsMixin],
  components: {
    TooltipIcon,
    CheckboxStatesIcon,
    InputField,
    DatePickerComponent: () => import('@/components/DatePickerComponent.vue')
  },
  data () {
    return {
      mount: false,
      timeout: null,
      errorsCount: 0,
      addresses: [],
      spouseAddressSimilar: false,
      stepForm: {
        spouse_surname: {
          value: null,
          message: null
        },
        spouse_name: {
          value: null,
          message: null
        },
        spouse_patronymic: {
          value: null,
          message: null
        },
        spouse_address: {
          value: null,
          message: null
        },
        spouse_birthday: {
          value: null,
          message: null
        },
        spouse_birthplace: {
          value: null,
          message: null
        },
        spouse_workplace: {
          value: null,
          message: null
        }
      },
      validator: {
        spouseAddress: false
      }
    }
  },
  mounted () {
    this.mount = true
    if (localStorage.alimentStepTwo) {
      Object.assign(this.stepForm, JSON.parse(localStorage.alimentStepTwo))
    }
  },
  watch: {
    stepForm: {
      handler: function () {
        localStorage.alimentStepTwo = JSON.stringify((this.stepForm))
        this.$store.dispatch('ADD_ALIMENT_STEPFORM', this.stepForm)
      },
      deep: true
    },
    'spouseAddressSimilar' (val) {
      if (val && this.getSpouseAddress) {
        if (!this.getSpouseAddress.value) return
        this.stepForm.spouse_address.value = this.getSpouseAddress.value
        this.$refs.spouse_address.search = this.getSpouseAddress.value.value
      } else {
        this.stepForm.spouse_address.value = null
        this.$refs.spouse_address.search = ''
        this.addresses = []
      }
    }
  },
  computed: {
    getSpouseAddress () {
      return this.$store.state.alimentStepForm.address || ''
    }
  },
  methods: {
    getField (name) {
      return this.$store.state.aliment_bot_page.values_bot.find(item => {
        return item.name === name
      })
    },
    spouseAddressFocus () {
      if (this.stepForm.spouse_address.value) {
        this.$refs.spouse_address.search = this.stepForm.spouse_address.value.value
      }

      this.validator.spouseAddress = !this.stepForm.spouse_address.value
    },
    validate () {
      // TODO сделать валидацию
      return true
    },
    getErrorsCount () {
      return this.errorsCount
    }
  }
}
</script>
