<template>
  <div v-if="Object.keys(getData).length">
    <h1 class="page-header">Проверьте данные</h1>
    <div class="form-group">
      <div class="form-group__body">
        <div class="check-data">
          <div class="row check-data__item" v-if="getData.name && getData.surname">
            <span class="col-3 check-data__text-muted">Ваще Ф.И.О:</span>
            <span class="col-9">
              {{ getData.name }} {{ getData.surname }}
              <span v-if="getData.patronymic">{{ getData.patronymic }}</span>
            </span>
          </div>
          <div class="row check-data__item" v-if="getData.email">
            <span class="col-3 check-data__text-muted">Ваш email:</span>
            <span class="col-9">{{ getData.email }}</span>
          </div>
          <div class="row check-data__item" v-if="getData.address">
            <span class="col-3 check-data__text-muted">Место Вашего жительства(по паспорту):</span>
            <span class="col-9">{{ getData.address }}</span>
          </div>
          <div class="row check-data__item" v-if="getData.mailing_address">
            <span class="col-3 check-data__text-muted">Место фактического пребывания:</span>
            <span class="col-9">{{ getData.mailing_address }}</span>
          </div>
          <div class="row check-data__item" v-if="getData.birthday">
            <span class="col-3 check-data__text-muted">Дата рождения:</span>
            <span class="col-9">{{ formatDate(getData.birthday) }}</span>
          </div>
          <div class="row check-data__item" v-if="getData.birthplace">
            <span class="col-3 check-data__text-muted">Место рождения:</span>
            <span class="col-9">{{ getData.birthplace }}</span>
          </div>
          <div class="row check-data__item" v-if="getData.document_type">
            <span class="col-3 check-data__text-muted">
              <span v-if="getData.document_type === 1">{{getField('document_type_one').title}}</span>
              <span v-else-if="getData.document_type === 2">{{getField('document_type_two').title}}</span>
              <span v-else-if="getData.document_type === 3">{{getField('document_type_three').title}}</span>
              <span v-else>{{getField('document_type_four').title}}</span>:
            </span>
            <span class="col-9" v-if="getData.document_number">{{ getData.document_number }}</span>
          </div>
          <div class="row check-data__item" v-if="getData.spouse_name && getData.spouse_surname">
            <span class="col-3 check-data__text-muted">Ф.И.О. Вашего супруга/ги:</span>
            <span class="col-9">
              {{ getData.spouse_name }} {{ getData.spouse_surname }}
              <span v-if="getData.spouse_patronymic">{{ getData.spouse_patronymic }}</span>
            </span>
          </div>
          <div class="row check-data__item" v-if="getData.spouse_address">
            <span class="col-3 check-data__text-muted">Адрес проживания супруга/ги:</span>
            <span class="col-9">{{ getData.spouse_address }}</span>
          </div>
          <div class="row check-data__item" v-if="getData.spouse_birthday">
            <span class="col-3 check-data__text-muted">Дата рождения супруга/ги (если знаете):</span>
            <span class="col-9">{{ formatDate(getData.spouse_birthday) }}</span>
          </div>
          <div class="row check-data__item" v-if="getData.spouse_birthplace">
            <span class="col-3 check-data__text-muted">Место рождения супруга/ги (если знаете):</span>
            <span class="col-9">{{ getData.spouse_birthplace }}</span>
          </div>
          <div class="row check-data__item" v-if="getData.spouse_workplace">
            <span class="col-3 check-data__text-muted">Место работы супруга/ги (если знаете):</span>
            <span class="col-9">{{ getData.spouse_workplace }}</span>
          </div>
          <div class="row check-data__item" v-if="getData.relationship_status">
            <span class="col-3 check-data__text-muted">Статус Ваших отношений с ответчиком:</span>
            <span class="col-9">{{ getRelationshipStatus }}</span>
          </div>
          <div v-if="getData.children && Object.keys(getData.children).length">
            <div class="row check-data__item" v-if="getData.children">
              <span class="col-3 check-data__text-muted">Дети:</span>
              <span class="col-9">
                <div v-for="(child, index) in getData.children" :key="index" class="check-data__mb">
                  <div v-if="child.name && child.surname" class="row">
                    <span class="col-12">
                    <span class="check-data__text-muted">ФИО:</span>
                        {{ child.surname }} {{ child.name }}
                        <span v-if="child.patronymic">{{ child.patronymic }}</span>
                    </span>
                  </div>
                  <div v-if="child.birthday" class="row">
                    <span class="col-12">
                      <span class="check-data__text-muted">Дата рождения:</span>
                      {{ formatDate(child.birthday) }}
                    </span>
                  </div>
                </div>
              </span>
            </div>
          </div>
          <div class="row check-data__item" v-if="getData.type">
            <span class="col-3 check-data__text-muted">Форма взыскания алиментов:</span>
            <span class="col-9">
              <div class="check-data__mb">
                  <div class="row">
                    <span class="col-12">{{ getAlimentForm }}</span>
                  </div>
                  <div class="row" v-if="getData.monthly_budget_for_child">
                    <span class="col-12">
                      <span class="check-data__text-muted">Сумма: </span>
                      {{ getData.monthly_budget_for_child }} руб.
                    </span>
                  </div>
                </div>
            </span>
          </div>
          <div class="row check-data__item">
            <span class="col-3 check-data__text-muted">Подать ходатайство о рассмотрении дела в Вашем отсутствии:</span>
            <span class="col-9" v-if="getData.petition_in_absence">Да</span>
            <span class="col-9" v-else>Нет</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'checkData',
  computed: {
    ...mapGetters(['getAlimentStepForm']),
    getRelationshipStatus () {
      let status
      switch (this.getData.relationship_status) {
        case 1:
          status = this.getField('relationship_status_one').title
          break
        case 2:
          status = this.getField('relationship_status_two').title
          break
        default:
          status = this.getField('relationship_status_three').title
          break
      }
      return status
    },
    getAlimentForm () {
      let form
      switch (this.getData.type) {
        case 1:
          form = this.getField('type_one').title
          break
        case 2:
          form = this.getField('type_two').title
          break
        case 3:
          form = this.getField('type_three').title
          break
        case 4:
          form = this.getField('type_four').title
          break
        default:
          form = this.getField('type_five').title
          break
      }
      return form
    },
    getData () {
      const data = this.getAlimentStepForm || {}
      return this.parse(data)
    }
  },
  methods: {
    getField (name) {
      return this.$store.state.aliment_bot_page.values_bot.find(item => {
        return item.name === name
      })
    },
    parse (data) {
      const forms = {}
      for (const form in data) {
        if (data[form].value) {
          switch (form) {
            case 'spouse_address' :
            case 'address' :
            case 'mailing_address' : {
              forms[form] = data[form].value.value
              break
            }
            case 'children' : {
              forms[form] = {}
              Object.keys(data[form].value).forEach(childCount => {
                forms[form][childCount] = {}
                for (const item in data[form].value[childCount]) {
                  forms[form][childCount][item] = data[form].value[childCount][item].value
                }
              })
              break
            }
            case 'court_id': {
              forms[form] = data[form].value.id
              break
            }
            default : {
              forms[form] = data[form].value
            }
          }
        }
      }
      return forms
    },
    formatDate (date) {
      return moment(date, 'YYYY.MM.DD').format('DD.MM.YYYY')
    }
  }
}
</script>
