<template>
  <div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ getField('type').title }}</span>
        <span class="form-group__tooltip" v-if="getField('type').description">
          <i
            class="icon"
            aria-describedby="tooltip"
            v-tooltip="{ content: getField('type').description, trigger: $mq === '700' ? 'click' : 'hover' }"
            tabindex="1"
          >
            <TooltipIcon/>
          </i>
        </span>
      </div>
      <div class="form-group__body" ref="type">
        <div class="form-group__body__radio">
          <div class="_row">
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.type.value" :value="1">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">{{ getField('type_one').title }}</span>
                <div class="form-group__tooltip" v-if="getField('type_one').description">
                  <i
                    class="icon"
                    aria-describedby="tooltip"
                    v-tooltip="{ content: getField('type_one').description, trigger: $mq === '700' ? 'click' : 'hover' }"
                    tabindex="1"
                  >
                    <TooltipIcon/>
                  </i>
                </div>
              </label>
            </div>
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.type.value" :value="2">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">{{ getField('type_two').title }}</span>
                <div class="form-group__tooltip" v-if="getField('type_two').description">
                  <i
                    class="icon"
                    aria-describedby="tooltip"
                    v-tooltip="{ content: getField('type_two').description, trigger: $mq === '700' ? 'click' : 'hover' }"
                    tabindex="1"
                  >
                    <TooltipIcon/>
                  </i>
                </div>
              </label>
            </div>
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.type.value" :value="3">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">{{ getField('type_three').title }}</span>
                <div class="form-group__tooltip" v-if="getField('type_three').description">
                  <i
                    class="icon"
                    aria-describedby="tooltip"
                    v-tooltip="{ content: getField('type_three').description, trigger: $mq === '700' ? 'click' : 'hover' }"
                    tabindex="1"
                  >
                    <TooltipIcon/>
                  </i>
                </div>
              </label>
            </div>
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.type.value" :value="4">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">{{ getField('type_four').title }}</span>
                <div class="form-group__tooltip" v-if="getField('type_four').description">
                  <i
                    class="icon"
                    aria-describedby="tooltip"
                    v-tooltip="{ content: getField('type_four').description, trigger: $mq === '700' ? 'click' : 'hover' }"
                    tabindex="1"
                  >
                    <TooltipIcon/>
                  </i>
                </div>
              </label>
            </div>
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.type.value" :value="5">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">{{ getField('type_five').title }}</span>
                <div class="form-group__tooltip" v-if="getField('type_five').description">
                  <i
                    class="icon"
                    aria-describedby="tooltip"
                    v-tooltip="{ content: getField('type_five').description, trigger: $mq === '700' ? 'click' : 'hover' }"
                    tabindex="1"
                  >
                    <TooltipIcon/>
                  </i>
                </div>
              </label>
            </div>
            <div class="_col-12" v-if="stepForm.type.value === 3 || stepForm.type.value === 5" ref="monthly_budget_for_child">
              <ValidationProvider
                name=" "
                rules="required"
                v-slot="{ errors }"
                style="width: 100%"
              >
                <InputField
                  :type="'number'"
                  :required="true"
                  :inputmode="'decimal'"
                  :min="0"
                  :title="getField('monthly_budget_for_child').title"
                  :placeholder="getField('monthly_budget_for_child').title"
                  v-model="stepForm.monthly_budget_for_child.value"
                >
                  <span class="form-group__message__error" v-if="!stepForm.monthly_budget_for_child.message">
                    {{ errors[0] }}
                  </span>
                  <span
                    class="form-group__message__error"
                    v-if="!stepForm.monthly_budget_for_child.value && stepForm.monthly_budget_for_child.message"
                  >
                    {{ stepForm.monthly_budget_for_child.message }}
                  </span>
                </InputField>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <span class="form-group__message__error" v-if="stepForm.type.value === null && stepForm.type.message">
          {{ stepForm.type.message }}
        </span>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ getField('petition_in_absence').title }}</span>
        <span class="form-group__tooltip" v-if="getField('petition_in_absence').description">
          <i
            class="icon"
            aria-describedby="tooltip"
            v-tooltip="{ content: getField('petition_in_absence').description, trigger: $mq === '700' ? 'click' : 'hover' }"
            tabindex="1"
          >
            <TooltipIcon/>
          </i>
        </span>
      </div>
      <div class="form-group__body" ref="petition_in_absence">
        <div class="form-group__body__radio">
          <div>
            <label class="form-group__radio">
              <input type="radio" v-model="stepForm.petition_in_absence.value" :value="1">
              <RadioStatesIcon/>
              <span class="form-group__radio__title">Да</span>
            </label>
          </div>
          <div>
            <label class="form-group__radio">
              <input type="radio" v-model="stepForm.petition_in_absence.value" :value="0">
              <RadioStatesIcon/>
              <span class="form-group__radio__title">Нет</span>
            </label>
          </div>
        </div>
        <span
          class="form-group__message__error"
          v-if="stepForm.petition_in_absence.value === null && stepForm.petition_in_absence.message">
          {{ stepForm.petition_in_absence.message }}
        </span>
      </div>
    </div>
    <div class="form-group" ref="agree">
      <div class="form-group__body">
        <ValidationProvider
          name=" "
          rules="required"
          v-slot="{ errors }"
          style="width: 100%"
        >
          <label class="form-group__radio">
            <input
              type="checkbox"
              v-model="stepForm.agree.value"
              required
              :true-value="1"
              :false-value="null"
            >
            <CheckboxStatesIcon/>
            <span class="form-group__radio__title">Согласен на обработку и хранение персональных данных и
              <router-link :to="{ name: 'confidentiality' }" target="_blank">Политикой конфиденциальности</router-link>
              <br/>
              и <router-link :to="{ name: 'agreement' }" target="_blank">Пользовательским соглашением</router-link>
            </span>
          </label>
          <div class="form-group__message__error" v-if="!stepForm.agree.value && errors[0]">
            Примите условия соглашения
          </div>
          <div class="form-group__message__error" v-else-if="stepForm.agree.value === null && stepForm.agree.message">
            {{ stepForm.agree.message }}
          </div>
        </ValidationProvider>
      </div>
    </div>
    <div  v-if="showCustomCourts" class="form-group__attention" ref="showCustomCourts">
      <div class="form-group">
        <div class="form-group__header">
          <span class="form-group__header__title">{{ getField('court_id').title }}</span>
          <div class="form-group__tooltip" v-if="getField('court_id').description">
            <i class="icon" aria-describedby="tooltip" v-tooltip="{ content: getField('court_id').description, trigger: $mq === '700' ? 'click' : 'hover' }" tabindex="1">
              <TooltipIcon/>
            </i>
          </div>
        </div>
        <div class="form-group__body">
          <div class="_row">
            <div class="_col-12">
              <VSelect
                label="name"
                v-model="stepForm.court_id.value"
                :options="localCourts"
                :placeholder="getField('court_id').title"
                :searchable="true"
                @search="fetchLocalCourts"
                :filterable="false"
              >
                <template v-slot:no-options="{ _search, searching, loading }">
                  <template v-if="searching">
                    <template v-if="!loading && !localCourts.length">Ничего не найдено</template>
                    <template v-else>Загрузка...</template>
                  </template>
                  <template v-else>Начните вводить адрес и название</template>
                </template>
              </VSelect>
            </div>
          </div>
        </div>
      </div>
    </div>
    <checkData/>
  </div>
</template>

<script>
import TooltipIcon from 'components/svg/Tooltip.vue'
import RadioStatesIcon from 'components/svg/RadioStates.vue'
import CheckboxStatesIcon from 'components/svg/CheckboxStates.vue'
import InputField from 'components/InputField.vue'
import checkData from './checkData.vue'
import { updateErrorsMixin } from '@/mixins/updateErrorsMixin.js'
import { resetValidationMixin } from '@/mixins/resetValidationMixin.js'
import { scrollToErrorsMixin } from '@/mixins/scrollToErrorsMixin.js'

export default {
  name: 'AlimentStepFour',
  mixins: [updateErrorsMixin, resetValidationMixin, scrollToErrorsMixin],
  components: {
    TooltipIcon,
    RadioStatesIcon,
    CheckboxStatesIcon,
    InputField,
    checkData
  },
  props: {
    showCustomCourts: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      mount: false,
      timeout: null,
      errorsCount: 0,
      localCourts: [],
      stepForm: {
        petition_in_absence: {
          value: null,
          message: null
        },
        court_id: {
          value: null,
          message: null
        },
        type: {
          value: null,
          message: null
        },
        monthly_budget_for_child: {
          value: null,
          message: null
        },
        agree: {
          value: null,
          message: null
        }
      }
    }
  },
  mounted () {
    this.mount = true
    if (localStorage.AlimentStepFour) {
      Object.assign(this.stepForm, JSON.parse(localStorage.AlimentStepFour))
      this.stepForm.court_id = {
        value: null,
        message: null
      }
    }
  },
  watch: {
    stepForm: {
      handler: function () {
        if ((this.stepForm.type.value !== 3) && (this.stepForm.type.value !== 5)) {
          this.stepForm.monthly_budget_for_child.value = null
        }
        this.$store.dispatch('ADD_ALIMENT_STEPFORM', this.stepForm)
        localStorage.AlimentStepFour = JSON.stringify((this.stepForm))
      },
      deep: true
    }
  },
  methods: {
    getField (name) {
      return this.$store.state.aliment_bot_page.values_bot.find(item => {
        return item.name === name
      })
    },
    fetchLocalCourts (search, loading) {
      loading(true)
      this.$store.dispatch('GET_LOCAL_COURTS', search).then(({ data }) => {
        this.localCourts = data.courts
        loading(false)
      })
    },
    validate () {
      // TODO сделать валидацию
      return true
    },
    getErrorsCount () {
      return this.errorsCount
    }
  }
}
</script>
