export const updateErrorsMixin = {
  methods: {
    updateErrors (errors) {
      Object.keys(errors).forEach(message => {
        Object.keys(this.stepForm).forEach(field => {
          if (field === message && this.stepForm[message]) {
            this.stepForm[field].message = errors[message][0]
          } else {
            const regex = /(.+)(\.\d\.)(.+)/
            if (message.match(regex)) {
              if (message.match(regex)[3]) {
                const arrayName = message.match(regex)[1]
                const arrayIndex = message.match(regex)[2]
                const arrayField = message.match(regex)[3]
                if (field === arrayName && this.stepForm[field]) {
                  this.stepForm[field].value[arrayIndex.replace(/\./g, '')][arrayField].message = errors[message][0]
                }
              }
            }
          }
        })
      })
      this.countErrors()
    },
    countErrors () {
      this.errorsCount = 0
      Object.keys(this.stepForm).forEach(field => {
        if (this.stepForm[field].message) {
          this.errorsCount += 1
        }
        if (this.stepForm[field].value && typeof this.stepForm[field].value.forEach === 'function') {
          this.stepForm[field].value.forEach(fld => {
            Object.keys(fld).forEach(key => {
              if (fld[key] && fld[key].message) {
                this.errorsCount += 1
              }
            })
          })
        }
      })
    }
  }
}
