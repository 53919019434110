export const resetValidationMixin = {
  methods: {
    /**
     * Сброс сообщений от валидации laravel
     */
    resetValidation () {
      this.errorsCount = 0
      Object.keys(this.stepForm).forEach(field => {
        this.stepForm[field].message = null
        if (this.stepForm[field].value && typeof this.stepForm[field].value.forEach === 'function') {
          this.stepForm[field].value.forEach(fld => {
            Object.keys(fld).forEach(key => {
              if (fld[key] && fld[key].message) fld[key].message = null
            })
          })
        }
      })
    }
  }
}
