<template>
  <form class="main main--internal" ref="start" autocomplete="off" @submit.prevent="submitForm">
    <noscript>Включите JavaScript чтобы воспользоваться этим ботом</noscript>
    <div class="page-header__container">
      <h1 class="page-header">{{ navigator.title }}</h1>
      <StepBadge class="step-badge" :current-step="currentStep" :max-steps="maxSteps" @change="setStep"/>
    </div>
    <span class="page-header__subtitle">{{ steps[currentStep] }}</span>
    <div class="steps-form">
      <AlimentStepOne :key="0" v-show="currentStep === 0" ref="step0"/>
      <AlimentStepTwo :key="1" v-show="currentStep === 1" ref="step1"/>
      <AlimentStepThree :key="2" v-show="currentStep === 2" ref="step2" @countChild="setCountChild"/>
      <AlimentStepFour :key="3" v-show="currentStep === 3 && countChild" ref="step3" :show-custom-courts="showCustomCourts"/>
      <div class="steps-form__footer">
        <div class="steps-form__buttons">
          <button
            type="button"
            class="steps-prev button"
            :class="{
              'button--hollow': currentStep === 0,
              'button--fill': currentStep !== 0,
            }"
            @click="prevStep"
          >
            Назад
          </button>
          <button type="button" class="steps-next button button--fill" @click="nextStep">
            {{ currentStep !== maxSteps ? "Далее" : "Получить документ: " + bot.price + "₽" }}
          </button>
        </div>
        <div v-if="this.currentStep === this.maxSteps" class="form-group__header__title">
          Мы гарантируем конфиденциальность внесенных данных, а также возврат денег, если суд не примет наш иск.
          Есть вопросы и предложения? Мы с удовольствием их примем по тел. <a href="tel:+79882727273">+7 988 272 72 73</a> или support@sudbot.ru.
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import StepBadge from 'components/StepBadge.vue'
import AlimentStepOne from './steps/step1.vue'
import AlimentStepTwo from './steps/step2.vue'
import AlimentStepThree from './steps/step3.vue'
import AlimentStepFour from './steps/step4.vue'
import { scrollToErrorsMixin } from '@/mixins/scrollToErrorsMixin.js'

export default {
  name: 'AlimentBotPage',
  mixins: [scrollToErrorsMixin],
  components: {
    StepBadge,
    AlimentStepOne,
    AlimentStepTwo,
    AlimentStepThree,
    AlimentStepFour
  },
  async asyncData ({ store }) {
    await store.dispatch('GET_ALIMENT_BOT_PAGE')
  },
  data () {
    return {
      currentStep: 0,
      maxSteps: 3,
      errors: {},
      showCustomCourts: false,
      forms: {},
      countChild: 0
    }
  },
  computed: {
    navigator () {
      return this.$store.state.aliment_bot_page.navigator
    },
    bot () {
      return this.$store.state.aliment_bot_page.bot
    },
    steps () {
      return [
        'Ваши данные',
        'Данные ответчика',
        'Дети',
        'Алименты'
      ]
    },
    values () {
      return this.$store.state.aliment_bot_page.values_bot
    },
    getStepsData () {
      return this.$store.state.alimentStepForm
    }
  },
  methods: {
    setCountChild (value) {
      this.countChild = value
    },
    setStep (index) {
      this.currentStep = index
    },
    getField (name) {
      return this.values.find((item) => {
        return item.name === name
      })
    },
    resetValidation () {
      this.errors = {}
      for (let i = 0; i <= this.maxSteps; i++) {
        this.$refs['step' + i].resetValidation()
      }
    },
    submitForm () {
      this.resetValidation()
      const data = new FormData()
      const forms = this.getStepsData
      for (const form in forms) {
        if (forms[form].value) {
          switch (form) {
            case 'spouse_address':
            case 'address' :
            case 'mailing_address' : {
              data.append(form, forms[form].value.value)
              break
            }
            case 'children' : {
              Object.keys(forms[form].value).forEach(childCount => {
                for (const item in forms[form].value[childCount]) {
                  if (forms[form].value[childCount][item].value) {
                    data.append(`${form}[${childCount}][${item}]`, forms[form].value[childCount][item].value)
                  }
                }
              })
              break
            }
            case 'court_id': {
              data.append(form, forms[form].value.id)
              break
            }
            default : {
              data.append(form, forms[form].value)
            }
          }
        }
      }
      this.$store.dispatch('POST_ALIMENT_BOT', data).then(res => {
        // if (res.data.link) {
        // const link = document.createElement('a')
        // link.setAttribute('href', this.$store.state.api + res.data.link)
        // link.setAttribute('download', this.$store.state.api + res.data.link)
        // link.click()
        // }
        if (res.data.url) {
          location.href = res.data.url
        }
      }).catch(({ response }) => {
        if (response.status === 423) {
          if (response.data) {
            this.$swal({
              title: 'По Вашему адресу нет судебного участка.',
              text: 'Указать суд вручную?',
              icon: 'warning',
              buttons: {
                cancel: 'Нет',
                catch: {
                  text: 'Да',
                  value: 'catch'
                }
              }
            }).then((value) => {
              switch (value) {
                case 'catch':
                  this.showCustomCourts = true
                  break
                default:
                  break
              }
            }).then(() => this.$refs.step3.scrollToErrors('showCustomCourts'))
          } else {
            this.$swal('По Вашему адресу нет судебного участка.')
          }
          this.currentStep = 3
        } else if (response.status === 500) {
          this.$swal('Произошла ошибка, обратитесь в тех. поддержку')
        } else {
          this.$swal('Пожалуйста, проверьте правильность заполненных полей!')
          // Вывод ошибок валидаций от Laravel
          for (let i = 0; i <= this.maxSteps; i++) {
            this.$refs['step' + i].updateErrors(response.data)
          }
          new Promise((resolve, reject) => {
            this.$nextTick(() => {
              for (let i = 0; i <= this.maxSteps; i++) {
                if (this.$refs['step' + i].getErrorsCount() > 0) {
                  this.currentStep = i
                  resolve()
                }
              }
            })
          }).then(() => {
            const stepForm = this.$refs['step' + this.currentStep].stepForm
            Object.keys(stepForm).forEach(field => {
              if (stepForm[field].message) {
                return this.$refs['step' + this.currentStep].scrollToErrors(field)
              }
              if (stepForm[field].value && typeof stepForm[field].value.forEach === 'function') {
                let count = null
                stepForm[field].value.forEach((fld, index) => {
                  Object.keys(fld).forEach(key => {
                    if (fld[key] && fld[key].message) {
                      count = index
                    }
                  })
                })
                if (count !== null) return this.$refs['step' + this.currentStep].scrollToCountedErrors(field, count)
              }
            })
          })
        }
      })
    },
    prevStep () {
      this.currentStep > 0 ? this.currentStep-- : (this.currentStep = 0)
    },
    nextStep () {
      if (this.currentStep === this.maxSteps) {
        this.submitForm()
      } else {
        if (this.$refs['step' + this.currentStep].validate()) {
          if (this.currentStep === 2 && !this.countChild) return 0
          else if (this.currentStep < this.maxSteps) {
            this.currentStep++
            this.scrollToErrors('start')
          } else this.currentStep = this.maxSteps
        }
      }
    }
  },
  metaInfo () {
    const navigator = this.$store.state.aliment_bot_page.navigator
    return this.$seo(
      'common',
      navigator.title,
      navigator.meta_keywords,
      navigator.description,
      navigator.title,
      null,
      navigator.description
    )
  }
}
</script>
