<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ getField('fio').title }}</span>
        <span class="form-group__tooltip" v-if="getField('fio').description">
          <i
            class="icon"
            aria-describedby="tooltip"
            v-tooltip="{ content: getField('fio').description, trigger: $mq === '700' ? 'click' : 'hover' }"
            tabindex="1"
          >
            <TooltipIcon/>
          </i>
        </span>
      </div>
      <div class="form-group__body">
        <div class="_row">
          <div class="_col-4" ref="surname">
            <ValidationProvider
              name=" "
              rules="required"
              v-slot="{ errors }"
              style="width: 100%"
            >
              <InputField
                :required="true"
                :title="getField('surname').title"
                :placeholder="getField('surname').title"
                v-model="stepForm.surname.value"
                :autofocus="true"
              >
                <span class="form-group__message__error" v-if="!stepForm.surname.message">{{ errors[0] }}</span>
                <span class="form-group__message__error" v-if="!stepForm.surname.value && stepForm.surname.message">
                  {{ stepForm.surname.message }}
                </span>
              </InputField>
            </ValidationProvider>
          </div>
          <div class="_col-4" ref="name">
            <ValidationProvider
              name=" "
              rules="required"
              v-slot="{ errors }"
              style="width: 100%"
            >
              <InputField
                :required="true"
                :title="getField('name').title"
                :placeholder="getField('name').title"
                v-model="stepForm.name.value"
                :autofocus="true"
              >
                <span class="form-group__message__error" v-if="!stepForm.name.message">{{ errors[0] }}</span>
                <span class="form-group__message__error" v-if="!stepForm.name.value && stepForm.name.message">
                  {{ stepForm.name.message }}
                </span>
              </InputField>
            </ValidationProvider>
          </div>
          <div class="_col-4" ref="patronymic">
            <InputField
              :required="true"
              :title="getField('patronymic').title"
              :placeholder="getField('patronymic').title"
              v-model="stepForm.patronymic.value"
            >
              <span class="form-group__message__error" v-if="stepForm.patronymic.message">
                {{ stepForm.patronymic.message }}
              </span>
            </InputField>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ getField('email').title }}</span>
        <span class="form-group__tooltip" v-if="getField('email').description">
          <i class="icon" aria-describedby="tooltip"
             v-tooltip="{ content: getField('email').description, trigger: $mq === '700' ? 'click' : 'hover' }"
             tabindex="1">
            <TooltipIcon/>
          </i>
        </span>
      </div>
      <div class="form-group__body" ref="email">
        <div class="_row">
          <div class="_col-12">
            <ValidationProvider
              name=" "
              rules="required"
              v-slot="{ errors }"
              style="width: 100%"
            >
              <InputField
                :type="'email'"
                :required="true"
                :title="getField('email').title"
                :placeholder="getField('email').title"
                v-model="stepForm.email.value"
              >
                <span class="form-group__message__error" v-if="!stepForm.email.message">{{ errors[0] }}</span>
                <span class="form-group__message__error" v-if="stepForm.email.message">
                  {{ stepForm.email.message }}
                </span>
              </InputField>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ getField('birthday_and_birthplace').title }}</span>
        <div class="form-group__tooltip" v-if="getField('birthday_and_birthplace').description">
          <i
            class="icon" aria-describedby="tooltip"
            v-tooltip="{ content: getField('birthday_and_birthplace').description, trigger: $mq === '700' ? 'click' : 'hover' }"
            tabindex="1"
          >
            <TooltipIcon/>
          </i>
        </div>
      </div>
      <div class="form-group__body">
        <div class="_row">
          <div class="_col-4" ref="birthday">
            <DatePickerComponent
              class="w-100"
              v-if="mount"
              v-model="stepForm.birthday.value"
            />
            <span class="form-group__message__error" v-if="!stepForm.birthday.value && stepForm.birthday.message">
              {{ stepForm.birthday.message }}
            </span>
          </div>
          <div class="_col-8" ref="birthplace">
            <InputField
              :required="true"
              :title="getField('birthplace').title"
              :placeholder="getField('birthplace').title"
              v-model="stepForm.birthplace.value"
            >
              <span class="form-group__message__error" v-if="validator.birthplace && !stepForm.birthplace.message">
                Поле обязательно для заполнения
              </span>
              <span class="form-group__message__error" v-if="!stepForm.birthplace.value && stepForm.birthplace.message">
                {{ stepForm.birthplace.message }}
              </span>
            </InputField>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ getField('document_type').title }}</span>
      </div>
      <div class="form-group__body">
        <div class="form-group__body__radio">
          <div class="_row">
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.document_type.value" :value="1" @click="clearDocumentNumber">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">{{ getField('document_type_one').title }}</span>
              </label>
            </div>
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.document_type.value" :value="2" @click="clearDocumentNumber">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">{{ getField('document_type_two').title }}</span>
              </label>
            </div>
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.document_type.value" :value="3" @click="clearDocumentNumber">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">{{ getField('document_type_three').title }}</span>
              </label>
            </div>
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.document_type.value" :value="4" @click="clearDocumentNumber">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">{{ getField('document_type_four').title }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group" v-if="stepForm.document_type.value">
      <div class="form-group__body">
        <div class="_row">
          <div class="_col-12" ref="document_number">
            <ValidationProvider
              name=" "
              rules="required"
              v-slot="{ errors }"
              style="width: 100%"
            >
              <InputField
                v-if="stepForm.document_type.value === 1"
                :required="true"
                v-mask="'###-###-### ##'"
                :title="getField('document_number').title"
                :placeholder="getField('document_number').title"
                v-model="stepForm.document_number.value"
              >
                <span class="form-group__message__error" v-if="!stepForm.email.message">{{ errors[0] }}</span>
                <span class="form-group__message__error" v-if="!stepForm.document_number.value && stepForm.document_number.message">
                  {{ stepForm.document_number.message }}
                </span>
              </InputField>
            </ValidationProvider>
            <ValidationProvider
              name=" "
              rules="required"
              v-slot="{ errors }"
              style="width: 100%"
            >
              <InputField
                v-if="stepForm.document_type.value === 2"
                :required="true"
                v-mask="'############'"
                :title="getField('document_number').title"
                :placeholder="getField('document_number').title"
                v-model="stepForm.document_number.value"
              >
                <span class="form-group__message__error" v-if="!stepForm.email.message">{{ errors[0] }}</span>
                <span class="form-group__message__error" v-if="!stepForm.document_number.value && stepForm.document_number.message">
                  {{ stepForm.document_number.message }}
                </span>
              </InputField>
            </ValidationProvider>
            <ValidationProvider
              name=" "
              rules="required"
              v-slot="{ errors }"
              style="width: 100%"
            >
              <InputField
                v-if="stepForm.document_type.value === 3"
                :required="true"
                v-mask="'## ## ######'"
                :title="getField('document_number').title"
                :placeholder="getField('document_number').title"
                v-model="stepForm.document_number.value"
              >
                <span class="form-group__message__error" v-if="!stepForm.email.message">{{ errors[0] }}</span>
                <span class="form-group__message__error" v-if="!stepForm.document_number.value && stepForm.document_number.message">
                  {{ stepForm.document_number.message }}
                </span>
              </InputField>
            </ValidationProvider>
            <ValidationProvider
              name=" "
              rules="required"
              v-slot="{ errors }"
              style="width: 100%"
            >
              <InputField
                v-if="stepForm.document_type.value === 4"
                :required="true"
                v-mask="'## ## ######'"
                :title="getField('document_number').title"
                :placeholder="getField('document_number').title"
                v-model="stepForm.document_number.value"
              >
                <span class="form-group__message__error" v-if="!stepForm.email.message">{{ errors[0] }}</span>
                <span class="form-group__message__error" v-if="!stepForm.document_number.value && stepForm.document_number.message">
                  {{ stepForm.document_number.message }}
                </span>
              </InputField>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group" ref="relationship_status">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ getField('relationship_status').title }}</span>
        <span class="form-group__tooltip" v-if="getField('relationship_status').description">
          <i
            class="icon"
            aria-describedby="tooltip"
            v-tooltip="{ content: getField('relationship_status').description, trigger: $mq === '700' ? 'click' : 'hover' }"
            tabindex="1"
          >
            <TooltipIcon/>
          </i>
        </span>
      </div>
      <div class="form-group__body">
        <div class="form-group__body__radio">
          <div class="_row">
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.relationship_status.value" :value="1">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">{{ getField('relationship_status_one').title }}</span>
              </label>
            </div>
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.relationship_status.value" :value="2">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">{{ getField('relationship_status_two').title }}</span>
              </label>
            </div>
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.relationship_status.value" :value="3">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">{{ getField('relationship_status_three').title }}</span>
              </label>
            </div>
          </div>
        </div>
        <span
          class="form-group__message__error"
          v-if="stepForm.relationship_status.value === null && stepForm.relationship_status.message"
        >
          {{ stepForm.relationship_status.message }}
        </span>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ getField('is_mailing_address').title }}</span>
      </div>
      <div class="form-group__body">
        <div class="form-group__body__radio">
          <label class="form-group__radio">
            <input type="radio" v-model="stepForm.is_mailing_address.value" :value="1" @click="clearAddressMailing">
            <RadioStatesIcon/>
            <span class="form-group__radio__title">Да</span>
          </label>
          <label class="form-group__radio">
            <input type="radio" v-model="stepForm.is_mailing_address.value" :value="0" @click="clearAddressMailing">
            <RadioStatesIcon/>
            <span class="form-group__radio__title">Нет</span>
          </label>
        </div>
      </div>
    </div>
    <div class="form-group" v-if="stepForm.is_mailing_address.value">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ getField('passport_address').title }}</span>
        <div class="form-group__tooltip" v-if="getField('passport_address').description">
          <i
            class="icon"
            aria-describedby="tooltip"
            v-tooltip="{ content: getField('passport_address').description, trigger: $mq === '700' ? 'click' : 'hover' }"
            tabindex="1"
          >
            <TooltipIcon/>
          </i>
        </div>
      </div>
      <div class="form-group__body" ref="addresss">
        <div class="_row">
          <div class="_col-12">
            <VSelect
              label="value"
              ref="address"
              v-model="stepForm.address.value"
              :options="addresses"
              :placeholder="getField('address').title"
              :searchable="true"
              @search="fetchAddress"
              @search:focus="addressFocus"
              @search:blur="addressFocus"
              :filterable="false"
            >
              <template v-slot:no-options="{ _search, searching, loading }">
                <template v-if="searching">
                  <template v-if="!loading && !addresses.length">Ничего не найдено</template>
                  <template v-else>Загрузка...</template>
                </template>
                <template v-else>Начните вводить адрес</template>
              </template>
            </VSelect>
            <span class="form-group__message__error" v-if="validator.address && !stepForm.address.message">
              Поле обязательно для заполнения
            </span>
            <span class="form-group__message__error" v-if="!stepForm.address.value && stepForm.address.message">
              {{ stepForm.address.message }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group" v-if="stepForm.is_mailing_address.value === 0">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ getField('address_type').title }}</span>
      </div>
      <div class="form-group__body">
        <div class="form-group__body__radio">
          <div class="_row">
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.address_type.value" :value="1" @click="clearAddressType">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">Место жительства по паспорту (прописка)</span>
              </label>
            </div>
            <div class="_col-12">
              <label class="form-group__radio">
                <input type="radio" v-model="stepForm.address_type.value" :value="2" @click="clearAddressType">
                <RadioStatesIcon/>
                <span class="form-group__radio__title">Текущее место жительства - место фактического пребывания</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group" v-if="stepForm.address_type.value !== null">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ getField('address').title }}</span>
        <div class="form-group__tooltip" v-if="getField('address').description">
          <i
            class="icon"
            aria-describedby="tooltip"
            v-tooltip="{ content: getField('address').description, trigger: $mq === '700' ? 'click' : 'hover' }"
            tabindex="1"
          >
            <TooltipIcon/>
          </i>
        </div>
      </div>
      <div class="form-group__body" ref="addresss">
        <div class="_row">
          <div class="_col-12">
            <VSelect
              label="value"
              ref="address"
              v-model="stepForm.address.value"
              :options="addresses"
              :placeholder="getField('address').title"
              :searchable="true"
              @search="fetchAddress"
              @search:focus="addressFocus"
              @search:blur="addressFocus"
              :filterable="false"
            >
              <template v-slot:no-options="{ _search, searching, loading }">
                <template v-if="searching">
                  <template v-if="!loading && !addresses.length">Ничего не найдено</template>
                  <template v-else>Загрузка...</template>
                </template>
                <template v-else>Начните вводить адрес</template>
              </template>
            </VSelect>
            <span class="form-group__message__error" v-if="validator.address && !stepForm.address.message">
              Поле обязательно для заполнения
            </span>
            <span class="form-group__message__error" v-if="!stepForm.address.value && stepForm.address.message">
              {{ stepForm.address.message }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group" v-if="stepForm.address_type.value === 2">
      <div class="form-group__header">
        <span class="form-group__header__title">{{ getField('mailing_address').title }}</span>
        <div class="form-group__tooltip" v-if="getField('mailing_address').description">
          <i
            class="icon"
            aria-describedby="tooltip"
            v-tooltip="{ content: getField('mailing_address').description, trigger: $mq === '700' ? 'click' : 'hover' }"
            tabindex="1"
          >
            <TooltipIcon/>
          </i>
        </div>
      </div>
      <div class="form-group__body" ref="mailing_addresss">
        <div class="_row">
          <div class="_col-12">
            <VSelect
              label="value"
              ref="addresses"
              v-model="stepForm.mailing_address.value"
              :options="addresses"
              :placeholder="getField('mailing_address').title"
              :searchable="true"
              @search="fetchAddress"
              @search:focus="addressFocus"
              @search:blur="addressFocus"
              :filterable="false"
            >
              <template v-slot:no-options="{ _search, searching, loading }">
                <template v-if="searching">
                  <template v-if="!loading && !addresses.length">Ничего не найдено</template>
                  <template v-else>Загрузка...</template>
                </template>
                <template v-else>Начните вводить адрес</template>
              </template>
            </VSelect>
            <span class="form-group__message__error" v-if="validator.mailing_address && !stepForm.mailing_address.message">
              Поле обязательно для заполнения
            </span>
            <span class="form-group__message__error" v-if="!stepForm.mailing_address.value && stepForm.mailing_address.message">
              {{ stepForm.mailing_address.message }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from 'components/svg/Tooltip.vue'
import RadioStatesIcon from 'components/svg/RadioStates.vue'
import InputField from 'components/InputField.vue'
import { updateErrorsMixin } from '@/mixins/updateErrorsMixin.js'
import { resetValidationMixin } from '@/mixins/resetValidationMixin.js'
import { fetchAddressMixin } from '@/mixins/fetchAddressMixin.js'
import { scrollToErrorsMixin } from '@/mixins/scrollToErrorsMixin.js'

export default {
  name: 'AlimentStepOne',
  mixins: [updateErrorsMixin, resetValidationMixin, fetchAddressMixin, scrollToErrorsMixin],
  components: {
    DatePickerComponent: () => import('@/components/DatePickerComponent.vue'),
    TooltipIcon,
    RadioStatesIcon,
    InputField
  },
  data () {
    return {
      mount: false,
      timeout: null,
      errorsCount: 0,
      addresses: [],
      stepForm: {
        surname: {
          value: null,
          message: null
        },
        name: {
          value: null,
          message: null
        },
        address: {
          value: null,
          message: null
        },
        patronymic: {
          value: null,
          message: null
        },
        email: {
          value: null,
          message: null
        },
        relationship_status: {
          value: null,
          message: null
        },
        document_type: {
          value: null,
          message: null
        },
        document_number: {
          value: null,
          message: null
        },
        is_mailing_address: {
          value: null,
          message: null
        },
        address_type: {
          value: null,
          message: null
        },
        mailing_address: {
          value: null,
          message: null
        },
        birthday: {
          value: null,
          message: null
        },
        birthplace: {
          value: null,
          message: null
        }
      },
      validator: {
        address: false
      }
    }
  },
  mounted () {
    this.mount = true
    if (localStorage.alimentStepOne) {
      Object.assign(this.stepForm, JSON.parse(localStorage.alimentStepOne))
    }
  },
  watch: {
    stepForm: {
      handler: function () {
        localStorage.alimentStepOne = JSON.stringify((this.stepForm))
        this.$store.dispatch('ADD_ALIMENT_STEPFORM', this.stepForm)
      },
      deep: true
    }
  },
  methods: {
    addressFocus () {
      if (this.stepForm.address.value) {
        this.$refs.address.search = this.stepForm.address.value.value
      }

      this.validator.address = !this.stepForm.address.value
    },
    clearDocumentNumber () {
      this.stepForm.document_number.value = null
    },
    clearAddressMailing () {
      this.stepForm.address.value = null
      this.stepForm.mailing_address.value = null
      this.stepForm.address_type.value = null
    },
    clearAddressType () {
      this.stepForm.address.value = null
      this.stepForm.mailing_address.value = null
    },
    getField (name) {
      return this.$store.state.aliment_bot_page.values_bot.find(item => {
        return item.name === name
      })
    },
    validate () {
      // TODO сделать валидацию
      return true
    },
    getErrorsCount () {
      return this.errorsCount
    }
  }
}
</script>
